import React, {Fragment, useState, useEffect } from 'react';
import uuidv4 from 'uuid/v4';

import request from './request';

const APP_PRODUCTS_API_ROOT = "https://finance.ua/api/product/v1/"
const APP_COMPANIES_API_ROOT = "https://minfin.com.ua/api/deposits/company/"

const LOCALES = ['auto', 'ru', 'uk'];
const CURRENCIES = ['uah', 'usd', 'eur'];
const INVESTORS_GROUPS = ['fiz', 'jur', 'corporate', 'pensioner', 'children'];

let loaderScript = null;

const Form = () => {
  const [companies, setCompanies] = useState([]);
  const [currency, setCurrency] = useState('uah');
  const [investorsGroup, setInvestorsGroup] = useState('fiz');
  const [products, setProducts] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [productId, setProductId] = useState('');
  const [productVariantId, setProductVariantId] = useState('');
  const [locale, setLocale] = useState('');
  const [bgColor, setBgColor] = useState('rgba(0, 0, 0, 0)');
  const [borderColor, setBorderColor] = useState('rgba(0, 0, 0, 0)');
  const [borderWidth, setBorderWidth] = useState(0);
  const [urlParams, setUrlParams] = useState([]);

  const fetchCompanies = async () => {
    const requestURL = `${APP_COMPANIES_API_ROOT}?type=bank&deposits=only&locale=uk`;

    const response = await request({
      url: requestURL,
    });

    setCompanies(response.data);
  };

  const fetchProducts = async () => {
    const requestURL = `${APP_PRODUCTS_API_ROOT}deposits?currency=${currency.toUpperCase()}&investors-group=${investorsGroup}&locale=uk`;

    const response = await request({
      url: requestURL,
    });

    setProducts(response.data);
  };

  const getCompanyByFinanceId = (companyFinanceId) => (
    companies.find((company) => parseInt(companyFinanceId, 10) === parseInt(company.financeId, 10))
  );

  const getProductsByCompanyFinanceId = (companyFinanceId) => {
    return products.filter((product) => parseInt(product.vendorId, 10) === parseInt(companyFinanceId, 10));
  };

  const getProductById = (id) => (
    products.find((product) => parseInt(product.id, 10) === parseInt(id, 10))
  );

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
    setProductId('');
    setProductVariantId('');
  }

  const handleInvestorsGroupChange = (event) => {
    setInvestorsGroup(event.target.value);
    setProductId('');
    setProductVariantId('');
  };

  const handleCompanyIdChange = (event) => {
    setCompanyId(event.target.value);
    setProductId('');
    setProductVariantId('');
  };

  const handleProductIdChange = (event) => {
    setProductId(event.target.value);
    setProductVariantId('');
  };

  const handleProductVariantIdChange = (event) => {
    setProductVariantId(event.target.value)
  };

  const handleLocaleChange = (event) => {
    setLocale(event.target.value);
  }

  const handleBgColorChange = (event) => {
    setBgColor(event.target.value)
  }

  const handleBorderColorChange = (event) => {
    setBorderColor(event.target.value);
  }

  const handleBorderWidthChange = (event) => {
    setBorderWidth(event.target.value);
  }

  const handleAddUrlParamBtnClick = () => {
    setUrlParams([...urlParams, {}])
  };

  const handleRemoveUrlParamBtnClick = (event) => {
    const urlParamIndex = parseInt(event.target.dataset.index, 10);

    setUrlParams(urlParams.filter((item, index) => index !== urlParamIndex))
  };

  const handleUrlParamNameChange = (event) => {
    const urlParamIndex = parseInt(event.target.dataset.index, 10);

    setUrlParams(urlParams.map((item, index) => {
      if (index === urlParamIndex) {
        return {
          ...item,
          name: event.target.value
        }
      }

      return item;
    }));
  }

  const handleUrlParamValueChange = (event) => {
    const urlParamIndex = parseInt(event.target.dataset.index, 10);

    setUrlParams(urlParams.map((item, index) => {
      if (index === urlParamIndex) {
        return {
          ...item,
          value: event.target.value
        }
      }

      return item;
    }));
  }

  const handleCopyBtnClick = () => {
    const copyText = document.getElementById("widget-code-input");
    copyText.select();
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy");
    alert("Copied the text: " + copyText.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    fetchCompanies();
  }, []);
  useEffect(() => {
    fetchProducts(currency, investorsGroup);
  }, [currency, investorsGroup]);// eslint-disable-line
  
  const company = getCompanyByFinanceId(companyId);
  const companyProducts = getProductsByCompanyFinanceId(companyId);
  const product = getProductById(productId) || {};
  const productVariants = product.skus || [];
  const activeProductVariant = productVariants.find(
    variant => parseInt(variant.id, 10) === parseInt(productVariantId, 10)
  );

  const showOutput = productId && companyId && productVariantId && locale;
  const widgetId = showOutput ? `tw-${uuidv4()}` : '';

  const widgetInitDiv = showOutput ? `<div data-tw-id='${widgetId}'></div>` : '';
  const urlParamsStr = showOutput ? urlParams.map(param => param.name && param.value ? `${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}` : '').filter((item) => !!item).join('&') : '';

  const widgetInitScript = showOutput
    ? `
  window.tw=window.tw||function(c){(tw.q=tw.q||[]).push(c)};
  tw({
    id: '${widgetId}',
    name:'DepositCard',
    props:{
      productId:${parseInt(productId, 10)},
      productVariantId:${parseInt(productVariantId, 10)},
      productUrlParams: '${urlParamsStr}',
      companyId:${parseInt(company.id, 10)},
      locale:'${locale}',
      wrapperProps: {
        styles: {
          'background-color': '${bgColor} !important',
          'border-color': '${borderColor} !important',
          'border-width': '${borderWidth}px !important',
          'border-style': 'solid !important'
        }
      }
    }
  });`
    : "";

  const widgetCode = showOutput ? `
  ${widgetInitDiv}
  <script>
      ${widgetInitScript}
  </script>
  <script src='${process.env.APP_WIDGET_PRODUCTS_DEPLOY_URL}loader.js' async></script>
  ` : '';

  useEffect(() => {
    if (showOutput) {
      window.eval(widgetInitScript);

      if (!loaderScript) {
        loaderScript = document.createElement("script");
        loaderScript.src = `${process.env.APP_WIDGET_PRODUCTS_DEPLOY_URL}loader.js`;
        loaderScript.async = true;

        document.body.appendChild(loaderScript);
      }
    }
  });

  console.log(product, activeProductVariant);
  
  
  return (
    <form onSubmit={handleSubmit}>
      <div style={{ width: 500, margin: "auto" }}>
        <fieldset style={{ marginBottom: 30 }}>
          <legend>API</legend>

          <div>
            <div style={{ padding: 10 }}>
              <select
                onChange={handleInvestorsGroupChange}
                value={investorsGroup}
                required
                style={{ width: "100%" }}
              >
                <option value="" disabled>
                  Select investors group
                </option>
                {INVESTORS_GROUPS.map(group => {
                  return (
                    <option key={group} value={group}>
                      {group}
                    </option>
                  );
                })}
              </select>
            </div>

            <div style={{ padding: 10 }}>
              <select
                onChange={handleCurrencyChange}
                value={currency}
                required
                style={{ width: "100%" }}
              >
                <option value="" disabled>
                  Select currencies
                </option>
                {CURRENCIES.map(currenciesItem => {
                  return (
                    <option key={currenciesItem} value={currenciesItem}>
                      {currenciesItem}
                    </option>
                  );
                })}
              </select>
            </div>

            <div style={{ padding: 10 }}>
              <select
                onChange={handleCompanyIdChange}
                value={companyId}
                required
                style={{ width: "100%" }}
              >
                <option value="" disabled>
                  Select company
                </option>
                {companies.map(company => {
                  return (
                    <option key={company.financeId} value={company.financeId}>
                      {company.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div style={{ padding: 10 }}>
              <select
                onChange={handleProductIdChange}
                value={productId}
                required
                disabled={!companyId}
                style={{ width: "100%" }}
              >
                <option value="" disabled>
                  Select product
                </option>
                {companyProducts.map(product => {
                  return (
                    <option key={product.id} value={product.id}>
                      {product.title}
                    </option>
                  );
                })}
              </select>
            </div>

            <div style={{ padding: 10 }}>
              <select
                onChange={handleProductVariantIdChange}
                value={productVariantId}
                required
                disabled={!productId}
                style={{ width: "100%" }}
              >
                <option value="" disabled>
                  Select product variant id
                </option>
                {productVariants.map(productVariant => {
                  return (
                    <option key={productVariant.id} value={productVariant.id}>
                      {productVariant.id}
                    </option>
                  );
                })}
              </select>
            </div>

            <div style={{ padding: 10 }}>
              <select
                onChange={handleLocaleChange}
                value={locale}
                required
                style={{ width: "100%" }}
              >
                <option value="" disabled>
                  Select locale
                </option>
                {LOCALES.map(locale => {
                  return (
                    <option key={locale} value={locale}>
                      {locale}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </fieldset>

        <fieldset style={{ marginBottom: 30 }}>
          <legend>Style</legend>

          <div>
            <div style={{ padding: 10, textAlign: "left" }}>
              <label
                style={{ paddingRight: 10, width: 150, display: "inline-flex" }}
              >
                Select bg color:
              </label>
              <input
                type="color"
                value={bgColor}
                onChange={handleBgColorChange}
              />
            </div>

            <div style={{ padding: 10, textAlign: "left" }}>
              <label
                style={{ paddingRight: 10, width: 150, display: "inline-flex" }}
              >
                Select border color:
              </label>
              <input
                type="color"
                value={borderColor}
                onChange={handleBorderColorChange}
              />
            </div>

            <div style={{ padding: 10, textAlign: "left" }}>
              <label
                style={{ paddingRight: 10, width: 150, display: "inline-flex" }}
              >
                Select border width:
              </label>
              <input
                type="number"
                value={borderWidth}
                min={0}
                onChange={handleBorderWidthChange}
              />
            </div>
          </div>
        </fieldset>

        <fieldset style={{ marginBottom: 30 }}>
          <legend>URL PARAMS</legend>

          <div style={{ padding: 10, textAlign: "left" }}>
            {urlParams.map((param, index) => (
              <div key={index} style={{ marginBottom: 10 }}>
                <input
                  type="text"
                  value={param.name || ""}
                  placeholder="paramName"
                  onChange={handleUrlParamNameChange}
                  data-index={index}
                />
                <input
                  type="text"
                  value={param.value || ""}
                  placeholder="paramValue"
                  onChange={handleUrlParamValueChange}
                  data-index={index}
                />
                <button
                  type="button"
                  data-index={index}
                  onClick={handleRemoveUrlParamBtnClick}
                >
                  &times;
                </button>
              </div>
            ))}

            <button
              type="button"
              onClick={handleAddUrlParamBtnClick}
              style={{ width: "100%" }}
            >
              Add param
            </button>
          </div>
        </fieldset>
      </div>

      {showOutput && (
        <Fragment>
          <fieldset style={{ marginBottom: 30 }}>
            <legend>characteristics</legend>

            <div>
              <p>
                <strong>Rate:</strong>{" "}
                <span>{activeProductVariant.depositRate}%</span>
              </p>

              <p>
                <strong>Bonus:</strong>{" "}
                <span>{activeProductVariant.bonusPercent}%</span>
              </p>

              <p>
                <strong>Effective rate:</strong>{" "}
                <span>{activeProductVariant.depositEffectiveRate}%</span>
              </p>

              <p>
                <strong>Interest payment:</strong>{" "}
                <span>{activeProductVariant.interestPayment}</span>
              </p>

              <p>
                <strong>Min term:</strong>{" "}
                <span>{activeProductVariant.depositTerm[0]} days</span>
              </p>

              <p>
                <strong>Max term:</strong>{" "}
                <span>{activeProductVariant.depositTerm[1]} days</span>
              </p>

              <p>
                <strong>Min amount:</strong>{" "}
                <span>
                  {activeProductVariant.amount[0]} {currency}
                </span>
              </p>

              <p>
                <strong>Max amount:</strong>{" "}
                <span>
                  {activeProductVariant.amount[1]} {currency}
                </span>
              </p>

              <p>
                <strong>Replenish:</strong>{" "}
                <span>{activeProductVariant.replenish}</span>
              </p>

              <p>
                <strong>URL:</strong>{" "}
                <span>{activeProductVariant.replenish}{`https://minfin.com.ua/${locale === 'uk' ? 'ua/' : ''}deposits/${product.id}-${product.slug}/?sku=${activeProductVariant.id}${urlParamsStr ? `&${urlParamsStr}` : ''}`}</span>
              </p>
            </div>
          </fieldset>

          <fieldset style={{ marginBottom: 30 }}>
            <legend>Preview</legend>

            <div dangerouslySetInnerHTML={{ __html: widgetInitDiv }} />
          </fieldset>

          <fieldset style={{ marginBottom: 30 }}>
            <legend>Code</legend>

            <div style={{ display: "block", position: "relative" }}>
              <button
                type="button"
                onClick={handleCopyBtnClick}
                style={{
                  padding: 15,
                  backgroundColor: "black",
                  color: "white",
                  position: "absolute",
                  top: 20,
                  right: 0
                }}
              >
                Copy
              </button>

              {!!widgetCode && (
                <input
                  style={{ transform: "scale(0)" }}
                  id="widget-code-input"
                  type="text"
                  value={`${widgetCode}`}
                  onChange={() => {}}
                />
              )}

              <code
                style={{
                  background: "lightgrey",
                  display: "block",
                  margin: "auto",
                  whiteSpace: "pre-wrap",
                  textAlign: "left"
                }}
              >
                {widgetCode}
              </code>
            </div>
          </fieldset>
        </Fragment>
      )}
    </form>
  );
}

export default Form;